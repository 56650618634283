import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, take, tap } from 'rxjs';
import { Params, Router } from '@angular/router';
import { emitOnce } from '@ngneat/elf';
import { LoginResponse } from 'src/app/auth/shared/interfaces/auth.model';
import { SocialAuthService } from '@abacritt/angularx-social-login';

import { SessionRepository } from './session.repository';
import { SkipTokenInterceptorHeader } from '../../interceptors/token.interceptor';
import { SkipErrorInterceptorHeader } from '../../interceptors/http-error.interceptor';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private readonly http = inject(HttpClient);
  private readonly sessionRepository = inject(SessionRepository);
  private readonly router = inject(Router);
  private readonly socialAuthService = inject(SocialAuthService);

  private logoutSocialUserAndResetStore(redirectUrl?: string, queryParams?: Params): void {
    // Note: we need to wait for social signOut promise to resolve before we
    // reset and navigate back to login due to a slight delay in signing out a user.
    // What happens due to the delay is that in the login component we subscribe to the
    // social auth state that still emits the user if the signOut has not completed.
    // Which results in the user just being logged in again.

    // We need to logout the social user by calling the signOut function
    // which can fail if the user is not signed in as a social user,
    // so in that case just catch the error and finally reset the store
    this.socialAuthService
      .signOut()
      .catch()
      .finally(() => {
        this.resetStore();

        if (redirectUrl) {
          this.router.navigate([redirectUrl], { queryParams });
        }
      });
  }

  /**
   * Reset application session store, navigate user to login page.
   *
   */
  private resetStore(): void {
    this.sessionRepository.resetStore();
  }

  /**
   * Logout user
   *
   * Request: DELETE
   *
   * URL: /logout
   *
   */
  logout(redirectUrl?: string, queryParams?: Params): void {
    this.http
      .delete(`${environment.api_url}/logout`, { headers: SkipErrorInterceptorHeader })
      .pipe(take(1))
      .subscribe();
    this.logoutSocialUserAndResetStore(redirectUrl, queryParams);
  }

  /**
   * Logout user from all sessions.
   *
   * Request: DELETE
   *
   * URL: /logout/all
   *
   * @returns Observable<Response>
   */
  logoutFromAllSessions(redirectUrl?: string): void {
    this.http.delete<Response>(`${environment.api_url}/logout/all`).pipe(take(1)).subscribe();
    this.logoutSocialUserAndResetStore(redirectUrl);
  }

  /**
   * Sends refresh token to get new user session data.
   *
   * Request: POST
   *
   * URL: /token/refresh
   *
   * @param token
   * @returns Observable<Response>
   */
  refreshUserToken(token: string): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        `${environment.api_url}/token/refresh`,
        { refresh_token: token },
        { headers: SkipTokenInterceptorHeader },
      )
      .pipe(
        tap(response => {
          const { token_key, refresh_token, user, quizToken } = response;

          if (token_key && refresh_token && user && quizToken) {
            emitOnce(() => {
              this.sessionRepository.setSessionTokens({ token_key, refresh_token });
              this.sessionRepository.setQuizToken(quizToken);
              this.sessionRepository.setUser(user);
              this.sessionRepository.setAccount(user.account.index_name);
            });
          }

          this.sessionRepository.clearRequestStatus();
        }),
        this.sessionRepository.trackSessionRequestStatus('session'),
      );
  }
}
