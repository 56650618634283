import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AnonAssessmentRepository } from 'src/app/assessments/store/anon-assessment.repository';
import { AssessmentRepository } from 'src/app/assessments/store/assessment.repository';
import { SessionRepository } from 'src/app/core/store/session/session.repository';
import { SessionService } from 'src/app/core/store/session/session.service';
import { environment } from 'src/environments/environment';

/** A header to skip the server error interceptor */
export const SkipTokenInterceptor = 'X-Skip-Token-Interceptor';
/** A header to skip the server error interceptor */
export const SkipTokenInterceptorHeader = new HttpHeaders({
  'X-Skip-Token-Interceptor': '',
});

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionRepo = inject(SessionRepository);
  const sessionService = inject(SessionService);
  const assessmentRepo = inject(AssessmentRepository);
  const anonAssessmentRepo = inject(AnonAssessmentRepository);

  // if the skip interceptor header is present we skip catching the error
  if (req.headers && req.headers.has(SkipTokenInterceptor)) {
    const headers = req.headers.delete(SkipTokenInterceptor);

    return next(req.clone({ headers }));
  }

  // check if anon assessment user exists
  let token = sessionRepo.getSessionTokens()?.token_key;

  let quizToken = sessionRepo.getQuizToken()?.token;

  const isAssessmentAnon = assessmentRepo.isAnonAssessment();

  const anonAssessmentTokens = anonAssessmentRepo.getTokens();

  if (isAssessmentAnon && anonAssessmentTokens) {
    token = anonAssessmentTokens.token_key;
    quizToken = anonAssessmentTokens.quizToken.token;
  }

  if (token) {
    req = req.clone({
      setHeaders: {
        'x-token': token,
      },
    });
  }

  if (req.url.includes(environment.quiz_api_url) && quizToken) {
    req = req.clone({
      setHeaders: {
        'QuizToken': quizToken,
      },
    });
  }

  function handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const refreshToken = sessionRepo.getSessionTokens()?.refresh_token;
    if (refreshToken) {
      // switchMap is used to send last request again after token refresh
      return sessionService.refreshUserToken(refreshToken).pipe(
        switchMap(response => {
          request = request.clone({
            setHeaders: {
              'x-token': response.token_key,
            },
          });

          return next(request);
        }),
      );
    } else {
      return next(request);
    }
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        (error.status === 401 && error.statusText === 'Unauthorized') ||
        (error.status === 400 && error.error.transCode === 'exception.token.notValid') ||
        (error.status === 401 && error.error.transCode === 'exception.token.notValid') ||
        (error.status === 400 && error.error.transCode === 'exception.token.refresh.notValid') ||
        (error.status === 401 && error.error.transCode === 'exception.token.refresh.notValid') ||
        (error.status === 400 && error.error.transCode === 'Expired JWT Token')
      ) {
        return handleRefreshToken(req, next).pipe(
          catchError((err: HttpErrorResponse) => {
            // if refresh token returns error logout user and reset store
            const account = sessionRepo.getAccount();

            let redirectUrl = '';

            if (account) {
              redirectUrl = `/${account}/login`;
            } else {
              redirectUrl = `/login`;
            }

            sessionService.logout(redirectUrl);

            return throwError(() => err);
          }),
        );
      }

      return throwError(() => error);
    }),
  );
};
