import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { persistState } from '@ngneat/elf-persist-state';
import {
  clearRequestsStatus,
  createRequestsStatusOperator,
  selectIsRequestPending,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Observable } from 'rxjs';
import { customSessionStorageStrategy } from '@app-shared/functions/custom-storage-strategies';
import { User } from 'src/app/core/store/session/interfaces/session.model.ts';

import { AnonAssessmentInfo, AnonUserAssessment } from '../interfaces/anon-assessment.model';

interface AnonAssessmentStoreProps {
  assessment: AnonUserAssessment | null;
  user: User | null;
  tokens: {
    token_key: string;
    refresh_token: string;
    quizToken: {
      token: string;
      expires_at: string;
    };
  } | null;
  emailRequired: boolean;
  incognito: boolean;
  options: [];
  action: string | null;
}

const initialApplicationStore: AnonAssessmentStoreProps = {
  assessment: null,
  user: null,
  tokens: null,
  emailRequired: false,
  incognito: false,
  options: [],
  action: null,
};

@Injectable({ providedIn: 'root' })
export class AnonAssessmentRepository {
  private readonly store;

  public trackAnonAssessmentRequestStatus;

  assessmentRequestLoading$: Observable<boolean>;

  assessment$: Observable<AnonAssessmentStoreProps['assessment']>;

  constructor() {
    this.store = createStore(
      { name: 'anon-assessment-data' },
      withProps<AnonAssessmentStoreProps>(initialApplicationStore),
      withRequestsStatus<'anon-assessment'>(),
    );

    this.trackAnonAssessmentRequestStatus = createRequestsStatusOperator(this.store);

    // store select value > returns observable
    this.assessment$ = this.store.pipe(select(state => state.assessment));

    // loading states
    this.assessmentRequestLoading$ = this.store.pipe(selectIsRequestPending('anon-assessment'));

    persistState(this.store, {
      key: 'anon-assessment-data',
      storage: customSessionStorageStrategy,
    });
  }

  updateStoreData(anonAssessmentInfo: AnonAssessmentInfo): void {
    const tokens = {
      token_key: anonAssessmentInfo.tokens.token_key,
      refresh_token: anonAssessmentInfo.tokens.refresh_token,
      quizToken: {
        token: anonAssessmentInfo.tokens.quizToken.token,
        expires_at: anonAssessmentInfo.tokens.quizToken.expires_at,
      },
    };

    this.store.update(setProp('assessment', anonAssessmentInfo.userassessment));
    this.store.update(setProp('action', anonAssessmentInfo.action));
    this.store.update(setProp('emailRequired', anonAssessmentInfo.emailRequired));
    this.store.update(setProp('incognito', anonAssessmentInfo.incognito));
    this.store.update(setProp('options', anonAssessmentInfo.options));
    this.store.update(setProp('tokens', tokens));
    this.store.update(setProp('user', anonAssessmentInfo.tokens.user));
  }

  updateUser(user: User): void {
    this.store.update(setProp('user', user));
  }

  // store get value once

  getAssessment(): AnonAssessmentStoreProps['assessment'] {
    return this.store.getValue().assessment;
  }

  getUser(): AnonAssessmentStoreProps['user'] {
    return this.store.getValue().user;
  }

  emailRequired(): AnonAssessmentStoreProps['emailRequired'] {
    return this.store.getValue().emailRequired;
  }

  isAssessmentIncognito(): AnonAssessmentStoreProps['incognito'] {
    return this.store.getValue().incognito;
  }

  getTokens(): AnonAssessmentStoreProps['tokens'] {
    return this.store.getValue().tokens;
  }

  // clear
  clearSession(): void {
    this.store.reset();
  }

  clearRequestStatus(): void {
    this.store.update(clearRequestsStatus());
  }
}
